



































































































































































































































































































































import { DigInputText } from '@digithia/input/text'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  Cga,
  FORMES_JURIDIQUES,
  REGIMES_FISCAL,
  REGIMES_FISCAL_RNRS,
  REGIMES_TVA_DECLARATION,
  REGIMES_FISCAL_DE_DROIT,
} from '@/model/Project'
import { GENDERS, User } from '@/model/User'
import utils, { Utils } from '../../../utils/Utils'
import { DigInputPhone } from '@digithia/input'

@Component({})
export default class CgaFormComp extends Vue {
  @Prop() cga!: Cga
  @Prop() tva!: boolean
  @Prop() user!: User

  mobile = ''
  formeJuridique = ''
  regimeFiscal = ''
  regimeFiscalRnRs = ''
  regimeFiscalDeDroit = ''
  regimeTvaDeclaration = ''
  GENDERS = GENDERS
  FORMES_JURIDIQUES = FORMES_JURIDIQUES
  REGIMES_FISCAL = REGIMES_FISCAL

  get isAdmin(): boolean {
    return this.$store.getters.isAdmin
  }

  mounted() {
    const inputSiret = document.querySelector('#cga-siret') as DigInputText
    inputSiret.validator = Utils.checkSiret
    inputSiret.errors.validator = `Le numéro SIRET est invalide`
    inputSiret.mask = `999 999 999 99999`

    const mobile = document.querySelector('#cga-mobile') as DigInputPhone
    mobile.errors.invalid = `Le téléphone est invalide`
  }

  updateField(key: any, value: any) {
    console.log(key, value)
    this.$emit('update', { key, value })
    if (key === 'dateDebutExercice') {
      const date = utils.dateToLastDayOfTheYear(value)
      this.$emit('update', { key: 'dateFinExercice', value: date })
    }
  }

  @Watch('cga', { immediate: true })
  onUserChanges() {
    if (this.user) {
      this.mobile = this.user.mobile || ''
    }

    if (!this.cga.formeJuridique) {
      this.formeJuridique = FORMES_JURIDIQUES.ENTREPRISE_INDIVIDUELLE
    }
    if (!this.cga.regimeFiscal) {
      this.regimeFiscal = REGIMES_FISCAL.BIC
    }
    if (!this.cga.regimeFiscalRnRs) {
      this.regimeFiscalRnRs = REGIMES_FISCAL_RNRS.REEL_SIMPLIFIE
    }
    if (!this.cga.regimeFiscalDeDroit) {
      this.regimeFiscalDeDroit = REGIMES_FISCAL_DE_DROIT.REGIME_1
    }
    if (!this.cga.regimeTvaDeclaration) {
      this.regimeTvaDeclaration = REGIMES_TVA_DECLARATION.ANNUEL
    }
    if (
      this.cga.premiereAdhesion === undefined ||
      this.cga.premiereAdhesion === null
    ) {
      this.cga.premiereAdhesion = true
    }

    this.$emit('update', { key: 'mobile', value: this.mobile })
    this.$emit('update', { key: 'regimeFiscal', value: this.regimeFiscal })
    this.$emit('update', {
      key: 'regimeFiscalRnRs',
      value: this.regimeFiscalRnRs,
    })
  }
}
