























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Project } from '@/model/Project'
import { CustomFile } from '@/model/CustomFile'
import ProjectFormCard from './ProjectFormCard.vue'

@Component({ components: { ProjectFormCard } })
export default class ProjectDetails extends Vue {
  @Prop({}) project!: Project
  @Prop({ default: '' }) title!: string

  editing = false

  get address() {
    return `${this.project.address || ''} - ${this.project.postalCode ||
      ''} - ${this.project.city || ''}`
  }

  get imageTexte() {
    if (this.project.image) {
      return this.$t('editImage')
    } else {
      return this.$t('addImage')
    }
  }

  get canEditImage() {
    return true
  }

  get projectImage(): string {
    return this.$store.getters.projectImage
  }

  saveProjectImage(file: CustomFile) {
    this.$store
      .dispatch('addProjectImage', {
        base64: file.base64,
        extention: file.extention,
        projectId: this.project.id,
      })
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
      })
  }
}
