






















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { FiscalMandate } from '@/model/Project'
import { User } from '@/model/User'

@Component({})
export default class FiscalMandateForm extends Vue {
  @Prop() fiscalMandate!: FiscalMandate
  @Prop() user!: User
  french = true

  created() {
    if (this.user) {
      this.fiscalMandate.lastname = this.user.lastname
      this.fiscalMandate.firstname = this.user.firstname
      this.fiscalMandate.address = this.user.address
      this.fiscalMandate.postalCode = this.user.postalCode
      this.fiscalMandate.city = this.user.city
      this.french = !this.fiscalMandate.etranger
    }
  }

  @Watch('foreign')
  onForeignChanges() {
    this.fiscalMandate.etranger = this.foreign
  }

  get foreign(): boolean {
    return !this.french
  }

  set foreign(foreign: boolean) {
    this.french = !foreign
  }
}
