



















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class FormCard extends Vue {
  @Prop({ default: '' }) title!: string

  validate() {
    this.$emit('validated')
  }

  cancel() {
    this.$emit('canceled')
  }
}
