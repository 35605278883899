


















import { Component, Prop, Vue } from 'vue-property-decorator'
import PricingForm from '../PricingForm.vue'
import { Cga, Project } from '@/model/Project'
import { User } from '@/model/User'

@Component({
  components: {
    PricingForm,
  },
})
export default class LetterStepPricing extends Vue {
  @Prop({ required: true })
  step!: number
  @Prop()
  user!: User
  @Prop()
  projects!: Project[]
  @Prop()
  cga!: Cga

  get mainProject(): Project {
    return this.projects.find(p => p.main) as Project
  }

  updateProjectField(
    id: number | undefined,
    { field, value }: { field: keyof Project; value: any },
  ) {
    this.$store
      .dispatch('updateProjectField', {
        id,
        field,
        value,
      })
      .then(json => {
        if (!json.success) {
          this.$store.dispatch('toaster/toast', json)
          // this.project[field] = undefined
        }
      })
  }

  updateUserField({ field, value }: { field: keyof User; value: any }) {
    this.$store
      .dispatch('updateUserField', {
        id: this.user.id,
        field,
        value,
      })
      .then(json => {
        if (!json.success) {
          this.$store.dispatch('toaster/toast', json)
          this.user[field] = undefined
          console.log(this.user[field])
        }
      })
  }

  updateCgaField({ field, value }: { field: keyof Cga; value: any }) {
    this.cga[field] = value
  }
}
