









































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { User, GENDERS } from '@/model/User'
import { KeyLabel } from '@/model/Project'
import { DigInputPhone } from '@digithia/input'

@Component({})
export default class UserForm extends Vue {
  @Prop({ default: {} }) user!: User
  @Prop({ default: false }) create!: boolean

  GENDERS = GENDERS

  update(key: keyof User, value: any) {
    this.$emit('update', { key, value })
  }

  mounted() {
    const mobile = document.querySelector('#mobile') as DigInputPhone
    mobile.errors.invalid = `Le téléphone est invalide`
  }
}
