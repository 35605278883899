















import { Component, Vue, Prop } from 'vue-property-decorator'
import BankDetailsForm from '../BankDetailsForm.vue'
import { User } from '@/model/User'
import { BankDetails } from '@/model/Project'

@Component({
  components: { BankDetailsForm },
})
export default class LetterStepBank extends Vue {
  @Prop({ required: true })
  step!: number
  @Prop()
  user!: User
  @Prop()
  bankDetails!: BankDetails
}
