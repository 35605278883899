






















































































import { Component, Vue, Prop } from 'vue-property-decorator'
import ProjectForm from '../ProjectForm.vue'
import ProjectFormCreate from '../ProjectFormCreate.vue'
import { Project } from '@/model/Project'
import { User } from '@/model/User'

@Component({
  components: { ProjectForm, ProjectFormCreate },
})
export default class LetterStepProject extends Vue {
  @Prop({ required: true })
  step!: number
  @Prop({ required: true })
  user!: User
  @Prop({ required: true })
  projects!: Project[]

  projectCount = this.projects.length

  get mainProject(): Project {
    return this.projects.find(p => p.main) as Project
  }

  get otherProjects(): Project[] {
    return this.projects.filter(p => !p.main)
  }

  updateProjectCount(value: string) {
    const n = +value

    if (isNaN(n) || !n) return

    if (this.projects.length < n) {
      for (let i = this.projects.length; i < n; i++) {
        this.hardAddProject()
      }
    } else {
      const deleteProjects = this.projects.slice(n, this.projects.length)
      for (const project of deleteProjects) {
        this.hardDeleteProject(project.id)
      }
    }
  }

  updateProjectField(
    id: number | undefined,
    { field, value }: { field: keyof Project; value: any },
  ) {
    this.$store
      .dispatch('updateProjectField', {
        id,
        field,
        value,
      })
      .then(json => {
        if (!json.success) {
          this.$store.dispatch('toaster/toast', json)
        }
      })
  }

  newProject() {
    this.$store
      .dispatch('addProject', {
        project: { name: 'Ma location', main: false },
        userId: this.user.id,
      })
  }

  deleteProject(id: number | undefined) {
    this.$store.dispatch('deleteProject', id)
  }

  hardAddProject() {
    this.$store
      .dispatch('hardAddProject', {
        project: { name: 'Ma location', main: false },
        userId: this.user.id,
      })
  }

  hardDeleteProject(id: number | undefined) {
    this.$store.dispatch('hardDeleteProject', id)
  }
}
