











































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Project } from '@/model/Project'
import { DigInputText } from '@digithia/input'
import { Utils } from '@/utils/Utils'

@Component({})
export default class ProjectForm extends Vue {
  @Prop({ default: {} }) project!: Project

  updateProjectField(field: keyof Project, value: any) {
    this.$emit('update', { field, value })
  }

  mounted() {
    const inputSiret = document.querySelector('#siret') as DigInputText
    inputSiret.validator = Utils.checkSiret
    inputSiret.errors.validator = `Le numéro SIRET est invalide`
    inputSiret.mask = `999 999 999 99999`
  }
}
