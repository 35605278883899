
































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Cga, Project } from '@/model/Project'
import { NumberFormatter } from '../../../views/simulator/NumberFormatter'
import { User } from '@/model/User'
import utils from '@/utils/Utils'

const PRIX_BASE = 500
const PRIX_CGA = 50
const PRIX_TVA = 100
const PRIX_LOCATION = 150
const PRIX_IMMAT = 150
const PRIX_FIXE = 166.67
const TVA_RATIO = 1.2

@Component({})
export default class PricingChoiceComp extends Vue {
  @Prop() project!: Project
  @Prop() cga!: Cga
  @Prop() projects!: Project[]
  @Prop() user!: User

  get isAdmin(): boolean {
    return this.$store.getters.isAdmin
  }

  @Watch('project', { immediate: true })
  onProjectChanges(current: any, old: any) {
    if (old && old.siret === current.siret) return
    if (this.project.siret) {
      this.updateUserField('creation', false)
    } else {
      console.log(this.user.creation)
      this.updateUserField('creation', !!this.user.creation)
    }
  }

  updateProjectField(field: keyof Project, value: any) {
    this.$emit('updateProject', { field, value })
  }

  updateUserField(field: keyof User, value: any) {
    this.$emit('updateUser', { field, value })
  }

  updateFieldCga(key: any, value: any) {
    if (key === 'dateFinExercice') {
      const date = utils.dateToLastDayOfTheYear(value)
      this.$emit('updateCga', { field: 'dateFinExercice', value: date })
    } else {
      this.$emit('updateCga', { field: key, value: value })
    }
  }

  warningDate = false

  checkDate(value: any) {
    const date = utils.dateToLastDayOfTheYear(value)
    const year = +utils.UTCToYear(value)
    const now = utils.nowToMoment()
    if ((now.year() === year && now.month() === 11) || now.year() > year) {
      this.warningDate = true
    } else {
      this.warningDate = false
    }
  }

  get prixLocation(): number {
    return Math.max(this.projects.length - 1, 0) * PRIX_LOCATION
  }

  get prixLocationHT(): string {
    return NumberFormatter.format(
      this.prixLocation,
      NumberFormatter.euroOptions,
    )
  }

  get prixLocationTTC(): string {
    return NumberFormatter.format(
      this.prixLocation * TVA_RATIO,
      NumberFormatter.euroOptions,
    )
  }

  get prixAnnuel(): number {
    let prix = PRIX_BASE

    if (this.user?.cga) {
      prix += PRIX_CGA
    }

    if (this.project.tva) {
      prix += PRIX_TVA
    }

    return prix + this.prixLocation
  }

  get prixAnnuelHT(): string {
    return NumberFormatter.format(this.prixAnnuel, NumberFormatter.euroOptions)
  }

  get prixAnnuelTTC(): string {
    return NumberFormatter.format(
      this.prixAnnuel * TVA_RATIO,
      NumberFormatter.euroOptions,
    )
  }

  get prixFixe(): number {
    const prix = this.user?.creation ? PRIX_IMMAT : 0
    return prix
  }

  get prixFixeHT(): string {
    return NumberFormatter.format(this.prixFixe, NumberFormatter.euroOptions)
  }

  get prixFixeTTC(): string {
    return NumberFormatter.format(
      this.prixFixe * TVA_RATIO,
      NumberFormatter.euroOptions,
    )
  }

  get prixFixe2(): number {
    const prix = PRIX_FIXE
    return prix
  }

  get prixFixe2HT(): string {
    return NumberFormatter.format(this.prixFixe2, NumberFormatter.euroOptions)
  }

  get prixFixe2TTC(): string {
    return NumberFormatter.format(
      this.prixFixe2 * TVA_RATIO,
      NumberFormatter.euroOptions,
    )
  }

  get deduction(): number {
    const deduction = this.user?.cga ? this.prixAnnuel / 3 : this.prixAnnuel
    return deduction
  }

  get deductionHT(): string {
    return NumberFormatter.format(this.deduction, NumberFormatter.euroOptions)
  }

  get deductionTTC(): string {
    return NumberFormatter.format(
      this.deduction * TVA_RATIO,
      NumberFormatter.euroOptions,
    )
  }
}
