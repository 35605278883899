

















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import CgaForm from '../CgaForm.vue'
import { User } from '@/model/User'
import { Cga, Project } from '@/model/Project'

@Component({
  components: { CgaForm },
})
export default class LetterStepCga extends Vue {
  @Prop({ required: true })
  step!: number
  @Prop()
  user!: User
  @Prop()
  project!: Project
  @Prop()
  cga!: Cga

  updateCga({ key, value }: { key: keyof Cga; value: any }) {
    // this.cga[key] = value

    this.$emit('update', { key, value })
  }

  created() {
    if (this.user) {
      if (!this.cga.mobile) this.cga.mobile = this.user.mobile
      if (!this.cga.email) {
        this.cga.email = this.user.email
      }
      if (!this.cga.nom) this.cga.nom = this.user.lastname
      if (!this.cga.prenom) this.cga.prenom = this.user.firstname
      if (!this.cga.genre) this.cga.genre = this.user.gender
      if (!this.cga.dateNaissance) this.cga.dateNaissance = this.user.birthdate
      if (!this.cga.adresseCorrespondance)
        this.cga.adresseCorrespondance = this.user.address
      if (!this.cga.codePostalCorrespondance)
        this.cga.codePostalCorrespondance = this.user.postalCode
      if (!this.cga.villeCorrespondance)
        this.cga.villeCorrespondance = this.user.city
    }

    if (this.project) {
      this.cga.siret = this.project.siret
      if (!this.cga.adressePro) this.cga.adressePro = this.project.address
      if (!this.cga.codePostalPro)
        this.cga.codePostalPro = this.project.postalCode
      if (!this.cga.villePro) this.cga.villePro = this.project.city
    }
    if (
      this.cga.premiereAdhesion === undefined ||
      this.cga.premiereAdhesion === null
    ) {
      this.cga.premiereAdhesion = true
    }
  }

  @Watch('project', { immediate: true })
  onUserChanges() {
    if (this.project) {
      this.cga.siret = this.project.siret
      if (!this.cga.adressePro) this.cga.adressePro = this.project.address
      if (!this.cga.codePostalPro)
        this.cga.codePostalPro = this.project.postalCode
      if (!this.cga.villePro) this.cga.villePro = this.project.city
    }
  }
}
