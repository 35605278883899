















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { User, GENDERS } from '@/model/User'
import { CustomFile } from '@/model/CustomFile'
import UserFormCard from './UserFormCard.vue'
import Utils from '../../../utils/Utils'

@Component({ components: { UserFormCard } })
export default class UserDetails extends Vue {
  @Prop({ default: {} }) user!: User
  @Prop({ default: '' }) title!: string

  editing = false
  loadingActivation = false

  toHumanDate(date: string | undefined) {
    return Utils.UTCToShort(date)
  }

  get address() {
    return `${this.user.address || ''} - ${this.user.postalCode || ''} - ${this
      .user.city || ''}`
  }

  get imageTexte() {
    if (this.src) {
      return this.$t('editImage')
    } else {
      return this.$t('addImage')
    }
  }

  get canEditImage() {
    return this.$store.getters.loggedUser.id === this.user.id
  }

  get gender(): string {
    return this.user.gender === GENDERS.MALE
      ? 'M.'
      : this.user.gender === GENDERS.FEMALE
      ? 'Mme'
      : ''
  }

  src = ''
  created() {
    this.$store
      .dispatch('getUserImage', { userId: this.user.id })
      .then(json => {
        this.src = json.data
      })
  }

  saveUserImage(file: CustomFile) {
    this.$store
      .dispatch('addUserImage', {
        base64: file.base64,
        extention: file.extention,
        userId: this.user.id,
      })
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
        if (json.success) this.src = json.data
      })
  }

  askActivation() {
    this.loadingActivation = true
    this.$store
      .dispatch('askActivation', this.user.id)
      .then(json => {
        this.loadingActivation = false
        this.$store.dispatch('toaster/toast', json)
      })
      .catch(() => {
        this.loadingActivation = false
      })
  }
}
