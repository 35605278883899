














































import { Component, Vue, Prop, Ref } from 'vue-property-decorator'
import { PERSONNES, User } from '@/model/User'
import UserDetails from '../../user/UserDetails.vue'

@Component({
  components: {
    UserDetails,
  },
})
export default class LetterStepUser extends Vue {
  @Prop({ required: true })
  step!: number
  @Prop({ required: true })
  user!: User
  @Prop({ required: true })
  isAdmin!: boolean

  PERSONNES = PERSONNES

  @Ref('userDetails') readonly userDetailsEl!: any

  get editing(): boolean {
    return this.userDetailsEl.editing
  }

  updateUser(user: User, toast = true) {
    this.$store
      .dispatch('updateOwnData', {
        id: this.user.id,
        user: user,
      })
      .then(json => {
        if (toast) this.$store.dispatch('toaster/toast', json)
        if (json.success) {
          this.userDetailsEl.editing = false
        }
      })
  }
}
