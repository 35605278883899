

















































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import ProjectDetails from './ProjectDetails.vue'
import UserDetails from '../user/UserDetails.vue'
import FiscalMandateForm from './FiscalMandateForm.vue'
import BankDetailsForm from './BankDetailsForm.vue'
import PricingForm from './PricingForm.vue'
import CgaForm from './CgaForm.vue'
import ProjectFormCreate from './ProjectFormCreate.vue'
import LetterStepUser from './step/LetterStepUser.vue'
import LetterStepProject from './step/LetterStepProject.vue'
import LetterStepPricing from './step/LetterStepPricing.vue'
import LetterStepMandate from './step/LetterStepMandate.vue'
import LetterStepBank from './step/LetterStepBank.vue'
import LetterStepCga from './step/LetterStepCga.vue'
import LetterStepTarif from './step/LetterStepTarif.vue'
import { BankDetails, Cga, FiscalMandate, Project } from '@/model/Project'
import { User } from '@/model/User'
import { DigInputController } from '@digithia/input'

@Component({
  components: {
    ProjectDetails,
    UserDetails,
    FiscalMandateForm,
    BankDetailsForm,
    PricingForm,
    CgaForm,
    ProjectFormCreate,
    LetterStepUser,
    LetterStepProject,
    LetterStepPricing,
    LetterStepMandate,
    LetterStepBank,
    LetterStepCga,
    LetterStepTarif,
  },
})
export default class LetterForm extends Vue {
  @Ref('letterStepUser') readonly letterStepUserEl!: LetterStepUser
  @Ref('fiscalMandateEl') readonly fiscalMandateEl!: any

  @Prop({ default: true }) isCreation!: boolean
  @Prop({ default: false }) loading!: boolean
  @Prop() user!: User
  @Prop() project!: Project
  @Prop() projects!: Project[]
  @Prop({
    default: () => {
      return {}
    },
  })
  bankDetails!: BankDetails
  @Prop({
    default: () => {
      return {
        premiereAdhesion: true,
        formeJuridique: null,
      }
    },
  })
  cga!: Cga
  @Prop({
    default: () => {
      return {}
    },
  })
  fiscalMandate!: FiscalMandate

  get isAdmin(): boolean {
    return this.$store.getters.isAdmin
  }

  cancel() {
    this.$emit('cancel')
  }

  updateCga({ key, value }: { key: keyof Cga; value: any }) {
    this.cga[key] = value
  }

  validateLetter() {
    if (this.letterStepUserEl.editing) {
      if (this.letterStepUserEl.editing) {
        window.scrollTo({ top: (this.letterStepUserEl.$el as any).offsetTop })
      }
      return this.$store.dispatch('toaster/toast', {
        message: this.$t('warningFormNotSubmitted'),
        type: 'warning',
      })
    }

    if (!DigInputController.checkInputsValidity('.letter-form')) {
      this.$store.dispatch('toaster/toast', {
        type: 'warning',
        message: this.$t('invalidForm'),
      })
    } else {
      this.$emit('validate', {
        id: this.project.id,
        bankDetails: this.bankDetails,
        fiscalMandate: this.fiscalMandate,
        cga: this.cga,
      })
    }
  }
}
