








import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { User } from '@/model/User'
import store from '../../../store'
import { Utils } from '../../../utils/Utils'
import FormCard from '../FormCard.vue'
import UserForm from './UserForm.vue'

@Component({
  components: { FormCard, UserForm },
})
export default class UserFormCard extends Vue {
  @Prop({ default: '' }) title!: string
  @Prop({ default: {} }) user!: User
  userForm: User = {}

  @Watch('user', { immediate: true })
  onUserChanges() {
    this.userForm = JSON.parse(JSON.stringify(this.user))
  }

  updateUser({ key, value }: { key: keyof User; value: any }) {
    this.userForm[key] = value
  }

  validate() {
    const valid = Utils.checkInputsValidity('.user-form-card dig-input-text')
    if (!valid) {
      store.dispatch('toaster/toast', {
        type: 'warning',
        message: `Le formulaire est invalide`,
      })
    } else {
      this.$emit('validated', this.userForm)
    }
  }

  cancel(noEvent = false) {
    this.userForm = JSON.parse(JSON.stringify(this.user))
    if (noEvent) return
    this.$emit('canceled')
  }
}
