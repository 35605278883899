import { NumberFormatOptions } from 'vue-i18n'

export class NumberFormatter {
  static locale = 'fr-FR'

  static euroOptions: NumberFormatOptions = {
    style: 'currency',
    currency: 'EUR',
  }

  static euroIntegerOptions: NumberFormatOptions = {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
  }

  static format(number: number, options: NumberFormatOptions = {}): string {
    return new Intl.NumberFormat(NumberFormatter.locale, options)
      .format(number)
      .replace(/ /g, ' ')
  }
}
