








import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Project } from '@/model/Project'
import store from '../../../store'
import { Utils } from '../../../utils/Utils'
import FormCard from '../FormCard.vue'
import ProjectForm from './ProjectForm.vue'

@Component({
  components: { FormCard, ProjectForm },
})
export default class ProjectFormCard extends Vue {
  @Prop({ default: '' }) title!: string
  @Prop({ default: {} }) project!: Project
  projectForm: Project = {}

  @Watch('project', { immediate: true })
  onProjectChanges() {
    this.projectForm = JSON.parse(JSON.stringify(this.project))
  }

  validate() {
    const valid = Utils.checkInputsValidity('.project-form-card dig-input-text')
    if (!valid) {
      store.dispatch('toaster/toast', {
        type: 'warning',
        message: `Le formulaire est invalide`,
      })
    } else {
      this.$emit('validated', this.projectForm)
    }
  }

  cancel(noEvent = false) {
    this.projectForm = JSON.parse(JSON.stringify(this.project))
    if (noEvent) return
    this.$emit('canceled')
  }

  updateProject({field, value}: any) {
    this.projectForm[field as keyof Project] = value
  }
}
