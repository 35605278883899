

















































import { Component, Prop, Vue } from 'vue-property-decorator'
import LetterForm from './LetterForm.vue'
import { Project, BankDetails, Cga, FiscalMandate } from '@/model/Project'
import { User } from '@/model/User'

@Component({
  components: {
    LetterForm,
  },
})
export default class DisplayLetter extends Vue {
  @Prop() user!: User
  @Prop() project!: Project
  @Prop() projects!: Project[]
  @Prop() letter!: string
  @Prop() canEdit!: boolean
  @Prop() disabled!: boolean

  editing = false
  loading = false

  get bankDetails(): BankDetails {
    return this.$store.getters.bankDetails
  }
  get cga(): Cga {
    return this.$store.getters.cga
  }
  get fiscalMandate(): FiscalMandate {
    return this.$store.getters.fiscalMandate
  }

  created() {
    this.$store.dispatch('getBankDetails', this.project.id)
    this.$store.dispatch('getFiscalMandate', this.project.id)
    this.$store.dispatch('getCga', this.project.id)
  }

  updateLetter(data: any) {
    this.loading = true
    console.log(data)
    this.$store.dispatch('updateLetter', data).then(json => {
      this.loading = false
      this.$store.dispatch('toaster/toast', json)
      if (json.success) {
        this.editing = false
      }
    })
  }

  validateLetter() {
    if (
      confirm('Êtes-vous certain de vouloir valider votre lettre de mission ?')
    ) {
      this.$emit('validated')
    }
  }
}
