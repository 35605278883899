
















































































import { DigInputText } from '@digithia/input/text'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { BankDetails } from '@/model/Project'
import { User } from '@/model/User'
import { Utils } from '../../../utils/Utils'

@Component({})
export default class BankDetailsForm extends Vue {
  @Prop() bankDetails!: BankDetails
  @Prop() user!: User

  created() {
    if (this.user) {
      this.bankDetails.name = this.user.lastname
      this.bankDetails.address = this.user.address
      this.bankDetails.postalCode = this.user.postalCode
      this.bankDetails.city = this.user.city
    }
  }

  mounted() {
    // const inputIban = document.querySelector('.input-iban') as DigInputText
    // inputIban.validator = Utils.checkIBAN
    // inputIban.errors.validator = `L'IBAN est invalide`

    const inputBic = document.querySelector('.input-bic') as DigInputText
    inputBic.validator = Utils.checkBIC
    inputBic.errors.validator = `Le code BIC est invalide`
  }
}
