















import { Component, Prop, Vue } from 'vue-property-decorator'
import FiscalMandateForm from '../FiscalMandateForm.vue'
import { FiscalMandate } from '@/model/Project'
import { User } from '@/model/User'

@Component({
  components: { FiscalMandateForm },
})
export default class LetterStepMandate extends Vue {
  @Prop({ required: true })
  step!: number
  @Prop()
  user!: User
  @Prop()
  fiscalMandate!: FiscalMandate
}
