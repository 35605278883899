















































































import { Project, TERRAINS, LOCATION_ETATS } from '@/model/Project'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class ProjectFormCreate extends Vue {
  @Prop({ required: false })
  project!: Project

  TERRAINS = TERRAINS
  LOCATION_ETATS = LOCATION_ETATS

  updateProjectField(field: keyof Project, value: any) {
    this.$emit('update', { field, value })
  }
}
