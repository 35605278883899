





















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Project } from '@/model/Project'
import { User } from '@/model/User'

const PRIX_BASE = 500
const PRIX_CGA = 50
const PRIX_TVA = 100
const PRIX_LOCATION = 150
const PRIX_IMMAT = 150
const PRIX_FIXE_TTC = 166.67

@Component({})
export default class BankDetailsForm extends Vue {
  @Prop() tarification!: Project
  @Prop() user!: User

  hintBaseTtc: string = ``
  setHintBaseTtc(value?: number) {
    this.hintBaseTtc = value ? `Soit ${((+value) * 1.2).toFixed(2)} €TTC` : ``
  }

  hintCGATtc: string = ``
  setHintCGATtc(value?: number) {
    this.hintCGATtc = value ? `Soit ${((+value) * 1.2).toFixed(2)} €TTC` : ``
  }

  hintTVATtc: string = ``
  setHintTVATtc(value?: number) {
    this.hintTVATtc = value ? `Soit ${((+value) * 1.2).toFixed(2)} €TTC` : ``
  }

  hintLocationTtc: string = ``
  setHintLocationTtc(value?: number) {
    this.hintLocationTtc = value ? `Soit ${((+value) * 1.2).toFixed(2)} €TTC` : ``
  }

  hintFixeTtc: string = ``
  setHintFixeTtc(value?: number) {
    this.hintFixeTtc = value ? `Soit ${((+value) * 1.2).toFixed(2)} €TTC` : ``
  }

  hintImmatriculationTtc: string = ``
  setHintImmatriculationTtc(value?: number) {
    this.hintImmatriculationTtc = value ? `Soit ${((+value) * 1.2).toFixed(2)} €TTC` : ``
  }

  updateProjectField(field: keyof Project, value: any) {
    this.$emit('updateProject', { field, value })
  }

  created() {
    this.tarification.libelleBase = this.tarification.libelleBase || `${this.$t('Tarification.defaultLibelleBase')}`
    this.tarification.tarifBase = this.tarification.tarifBase ?? PRIX_BASE
    this.tarification.libelleCGA = this.tarification.libelleCGA || `${this.$t('Tarification.defaultLibelleCGA')}`
    this.tarification.tarifCGA = this.tarification.tarifCGA ?? PRIX_CGA
    this.tarification.libelleTVA = this.tarification.libelleTVA || `${this.$t('Tarification.defaultLibelleTVA')}`
    this.tarification.tarifTVA = this.tarification.tarifTVA ?? PRIX_TVA
    this.tarification.libelleLocation = this.tarification.libelleLocation || `${this.$t('Tarification.defaultLibelleLocation')}`
    this.tarification.tarifLocation = this.tarification.tarifLocation ?? PRIX_LOCATION
    this.tarification.libelleImmatriculation = this.tarification.libelleImmatriculation || `${this.$t('Tarification.defaultLibelleImmatriculation')}`
    this.tarification.tarifImmatriculation = this.tarification.tarifImmatriculation ?? PRIX_IMMAT
    this.tarification.libelleFixe = this.tarification.libelleFixe || `${this.$t('Tarification.defaultLibelleFixe')}`
    this.tarification.tarifFixe = this.tarification.tarifFixe ?? PRIX_FIXE_TTC

    this.setHintBaseTtc(this.tarification.tarifBase ?? PRIX_BASE)
    this.setHintCGATtc(this.tarification.tarifCGA ?? PRIX_CGA)
    this.setHintTVATtc(this.tarification.tarifTVA ?? PRIX_TVA)
    this.setHintLocationTtc(this.tarification.tarifLocation ?? PRIX_LOCATION)
    this.setHintFixeTtc(this.tarification.tarifFixe ?? PRIX_FIXE_TTC)
    this.setHintImmatriculationTtc(this.tarification.tarifImmatriculation ?? PRIX_IMMAT)
  }

  @Watch('tarification', { immediate: true })
  onProjectChanges() {
    this.tarification.libelleBase = this.tarification.libelleBase || `${this.$t('Tarification.defaultLibelleBase')}`
    this.tarification.libelleCGA = this.tarification.libelleCGA || `${this.$t('Tarification.defaultLibelleCGA')}`
    this.tarification.libelleTVA = this.tarification.libelleTVA || `${this.$t('Tarification.defaultLibelleTVA')}`
    this.tarification.libelleLocation = this.tarification.libelleLocation || `${this.$t('Tarification.defaultLibelleLocation')}`
    this.tarification.libelleImmatriculation = this.tarification.libelleImmatriculation || `${this.$t('Tarification.defaultLibelleImmatriculation')}`
    this.tarification.libelleFixe = this.tarification.libelleFixe || `${this.$t('Tarification.defaultLibelleFixe')}`

    this.setHintBaseTtc(this.tarification.tarifBase)
    this.setHintCGATtc(this.tarification.tarifCGA)
    this.setHintTVATtc(this.tarification.tarifTVA)
    this.setHintLocationTtc(this.tarification.tarifLocation)
    this.setHintFixeTtc(this.tarification.tarifFixe)
    this.setHintImmatriculationTtc(this.tarification.tarifImmatriculation)
  }
}
