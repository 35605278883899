














import { Component, Vue, Prop } from 'vue-property-decorator'
import TarifForm from '../TarifForm.vue'
import { User } from '@/model/User'
import { Project } from '@/model/Project'

@Component({
  components: { TarifForm },
})
export default class LetterStepBank extends Vue {
  @Prop({ required: true })
  step!: number
  @Prop()
  user!: User
  @Prop()
  projects!: Project[]
  @Prop()
  project!: Project

  get mainProject(): Project {
    return this.projects.find(p => p.main) as Project
  }

  updateProjectField(
    id: number | undefined,
    { field, value }: { field: keyof Project; value: any },
  ) {
    this.$store
      .dispatch('updateProjectField', {
        id,
        field,
        value,
      })
      .then(json => {
        if (!json.success) {
          this.$store.dispatch('toaster/toast', json)
        }
      })
  }
}
